'use strict';

angular.module('core').controller('PartialsHarnessSerialController', ['$scope', '$rootScope','Wmoapi',  '_', function ($scope, $rootScope, Wmoapi, _) {

    $scope.harnessOptions = [];
    $scope.analogHarness = false;
    $scope.selectedHarness = 0;
    $scope.harnessSerialNumber = '';
    $scope.harnessInfo = {sku: ''};
    $scope.noHarnessSerialNumber = false;
    $scope.harnessValidationResponse = {
        status: -1,
        message: ''
    };
    $scope.harnessValidationMessageClass = '';
    $scope.showHarnessValidationMessage = false;
    $scope.harnessSerialInfo = false;
    $scope.remainingCredit = $rootScope.globalUser.harnessCredit;

    init();

    function init() {
        $scope.harnessOptions = _.filter(_.concat($scope.$parent.responseData.currentAccessories.optional, $scope.$parent.responseData.currentAccessories.required), ['isProductCategoryTHarness' , true]);
        $scope.analogHarness = $scope.$parent.responseData.currentAccessories.isHardwired;
    }

    $scope.submit = function (harnessSerialForm) {
        if (harnessSerialForm.$valid){
            validateHarnessSerial(false);
        }
    };

    $scope.useCredit = function () {
        $scope.harnessSerialNumber = '';
        $scope.harnessForm.$setPristine(true);
        validateHarnessSerial(true);
    };

    $scope.harnessSerialInfoToggle = function () {
        $scope.harnessSerialInfo = !$scope.harnessSerialInfo;
    };

    $scope.harnessClick = function (harnessId) {
        $scope.selectedHarness = harnessId;
        $scope.showHarnessValidationMessage = false;

        if (harnessId !== 99999) {
            $scope.harnessInfo = $scope.harnessOptions.find(harness => harness.id === harnessId);
            $scope.harnessSerialNumber = $scope.harnessOptions.find(harness => harness.id === harnessId).defaultSerial;
        } else {
            $scope.harnessInfo = {sku: ''};
            $scope.harnessSerialNumber = $scope.$parent.responseData.currentAccessories.defaultHardwiredSerial;
        }

    };

    $scope.resetHarnessValidation = function () {

        if ($scope.noHarnessSerialNumber) {
            $scope.noHarnessSerialNumber = false;
            $scope.showHarnessValidationMessage = false;
            $scope.harnessValidationResponse = {
                status: -1,
                message: ''
            };
        }
    };

    $scope.event = function () {
        setTHarnessSelection(
            $scope.selectedHarness === 99999 ? null : $scope.selectedHarness,
            $scope.selectedHarness === 99999,
            $scope.harnessSerialNumber,
            $scope.noHarnessSerialNumber);
    };

    $scope.viewCreditDetails = function () {
        window.WD_open_webbrowser('https://www.idataguides.com/hsc/');
    };

    function validateHarnessSerial (noSerialProvided) {
        $rootScope.isGlobalLoading = true;
        var parentRequestId = $scope.$parent.request.id;
        $scope.showHarnessValidationMessage = false;
        $scope.noHarnessSerialNumber = noSerialProvided;
        Wmoapi.validateHsnCredit(parentRequestId, $scope.selectedHarness, $scope.selectedHarness === 99999, $scope.harnessSerialNumber, noSerialProvided)
            .success(handleHarnessValidationSuccess)
            .error(handleHarnessValidationError);
    }

    function handleHarnessValidationSuccess (response) {
        $rootScope.isGlobalLoading = false;
        $scope.showHarnessValidationMessage = true;
        $scope.harnessValidationResponse.message = response.messageKey;
        $scope.harnessValidationResponse.status = response.status;

        switch ($scope.harnessValidationResponse.status) {
            case 0 :
                $scope.harnessValidationMessageClass = 'status-error';
                $scope.harnessForm.harnessSerialNumber.$setValidity('serial', false);
                break;
            case 1 :
                $scope.harnessValidationMessageClass = 'status-success';
                $scope.harnessForm.harnessSerialNumber.$setValidity('serial', true);
                break;
            case 2 :
                $scope.harnessValidationMessageClass = 'status-warning';
                $scope.harnessForm.harnessSerialNumber.$setValidity('serial', true);
                break;
            default:
                $scope.harnessValidationMessageClass = 'status-error';
        }
    }

    function handleHarnessValidationError (error) {
        $rootScope.isGlobalLoading = false;
        $scope.showHarnessValidationMessage = true;
        $scope.harnessValidationResponse.message = error;
        $scope.harnessValidationResponse.status = 0;
        $scope.harnessValidationMessageClass = 'status-error';
        $scope.harnessForm.harnessSerialNumber.$setValidity('serial', false);
    }

    function setTHarnessSelection(accessoryId, isHardwired, harnessSerial, noHarnessSerialNumber){
        $rootScope.isGlobalLoading = true;
        var parentRequestId = $scope.$parent.request.id;
        Wmoapi.setTHarness(parentRequestId, accessoryId, isHardwired, harnessSerial, noHarnessSerialNumber).then(checkIfHarnessSet, handleHarnessError);
    }

    function checkIfHarnessSet(response){
        $rootScope.isGlobalLoading = false;
        if (response.data.status === 0) handleHarnessValidationSuccess(response.data);
        else $scope.confirm();
    }

    function handleHarnessError(response){
        $rootScope.isGlobalLoading = false;
        $scope.showHarnessValidationMessage = true;
        $scope.harnessValidationResponse.message = response.error;
        $scope.harnessValidationResponse.status = 0;
        $scope.harnessValidationMessageClass = 'status-error';
    }

}]);
